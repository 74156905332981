import store from '/src/store';

const { follow, collection } = store.getters.staticData;

export default [
    {
        prop: 'companyNameHighlight',
        label: '客户名称',
        showOverflowTooltip: true,
        type: 'link',
    },
    { prop: 'industry', label: '行业类型', showOverflowTooltip: true, width: 100 },
    { prop: 'legalPersonName', label: '企业关键人', showOverflowTooltip: true, width: 100 },
    { prop: 'position', label: '职位', showOverflowTooltip: true, width: 100 },
    {
        prop: 'phones',
        label: '企业联系方式',
        popover: '全网公开企业联系方式，VIP会员可不限次数查看。',
        width: 140,
        showOverflowTooltip: true,
        buttons: [
            {
                name: 'phones',
                type: 'info',
                label: '解锁全部电话',
                class: 'contact',
            },
        ],
    },
    { prop: 'emails', label: '邮箱', showOverflowTooltip: true, width: 140 },
    { prop: 'regLocation', label: '地址', showOverflowTooltip: true, width: 180 },
    {
        prop: 'followStatus',
        label: '状态',
        popover: '在线标记客户的意向，VIP会员专属功能。',
        width: 120,
        buttons: [
            {
                name: 'followStatus',
                label: '',
                format(item, bitem, row) {
                    // console.log(row.follow, followLabels[row.follow]);
                    // 模拟 0 1 2
                    return follow[row.followStatus];
                    // return row.followStatus;
                },
            },
        ],
    },
    {
        prop: 'collectionStatus',
        label: '操作',
        popover: '重点客户进行收藏，VIP会员专属功能。',
        width: 120,
        buttons: [
            {
                name: 'collectionStatus',
                label: '',
                // class: 'collect',
                format(item, bitem, row) {
                    return collection[row.collectionStatus];
                    // return row.collectionStatus === '已收藏' ? '取消收藏' : '收藏';
                },
            },
        ],
    },
];