<template>
    <el-dialog
        title="全部标签"
        :visible.sync="dialogVisible"
        :close-on-click-modal="false"
        :append-to-body="true"
        width="654px"
        @close="handleToClose"
    >
        <div class="tag-list">
            <div
                v-for="(item, idx) in tagsList"
                :key="'tag-item-' + item.key + idx"
                class="tag-list-item"
            >
                <div class="item-title">{{ item.key }}</div>
                <div class="item-content">
                    <span
                        v-for="(citem, cidx) in item.children"
                        :key="'tag-value-' + item.key + idx + cidx"
                        :class="{
                            checked: citem.industryTagName === currentValue,
                        }"
                        @click="handleToCheck(citem)"
                        >{{ citem.industryTagName }}</span
                    >
                </div>
            </div>
        </div>
    </el-dialog>
</template>

<script>
// import { getPayData, getExportCfg } from '/src/utils/staticData';

// import { track } from '/src/directive/track/sendData';

export default {
    name: 'DialogFilterTag',
    model: {
        prop: 'dialogShow', // 指定自己的pfoo属性接受数值
        event: 'valChange', // 指定自己的event1事件回送数据给父组件
    },
    props: {
        dialogShow: {
            type: Boolean,
            default: false,
        },
        tagsData: {
            type: Object,
            default() {
                return {};
            },
        },
        // 选中值
        currentValue: {
            type: String,
            default() {
                return '';
            },
        },
    },
    data() {
        return {
            dialogVisible: this.dialogShow,
            tagsList: [],
        };
    },
    watch: {
        dialogShow(val) {
            this.dialogVisible = val;

            if (val) {
                this.handleToInit();
            }
        },
        dialogVisible(val) {
            this.$emit('valChange', val);
        },
    },
    // computed: {},
    // created() {},
    mounted() {},
    methods: {
        handleToInit() {
            const { tagsData } = this;

            const tmpData = [];

            Object.keys(tagsData).forEach(key => {
                tmpData.push({
                    key,
                    children: tagsData[key] || [],
                });
            });

            this.tagsList = tmpData;
        },

        handleToClose() {
            this.dialogVisible = false;
        },

        handleToCheck(item) {
            this.$emit('change', {
                type: 'checked',
                data: item.industryTagName,
            });

            this.handleToClose();
        },
    },
};
</script>

<style lang="scss" scoped>
.tag-list {
    padding: 10px 15px;
    max-height: 500px;
    overflow-y: auto;

    &-item {
        margin-bottom: 10px;
    }
}

.item-title {
    line-height: 1.8;
    font-size: 14px;
    color: #959595;
}

.item-content {
    line-height: 22px;
    font-size: 14px;
    color: #333;

    span {
        display: inline-block;
        margin: 0 15px 10px 0;
        cursor: pointer;

        &:hover {
            color: #1d6ff7;
        }

        &.checked {
            color: #1d6ff7;
            cursor: default;
        }
    }
}
</style>
