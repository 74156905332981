<template>
    <div class="filter-container">
        <div class="filter-header">
            <div class="filter-header-title">
                <i class="icons-filter"></i>
                <span>筛选</span>
            </div>
        </div>
        <div class="filter-body">
            <template v-for="(item, index) in filterData">
                <div
                    v-if="!item.hidden && item.data.length"
                    :key="item.name"
                    class="filter-item"
                    :class="item.name"
                >
                    <div class="item-label">{{ item.label }}</div>
                    <div class="item-value">
                        <el-button
                            v-for="(citem, cindex) in item.data"
                            :key="item.name + index + cindex"
                            type="text"
                            :class="{
                                checked: citem.checked,
                                hidden: !item.open && citem.hidden,
                            }"
                            @click="handleToCheck(item, citem)"
                            >{{ citem.label }}</el-button
                        >
                    </div>
                    <div v-if="item.name === 'industryTag'" class="item-action">
                        <el-button
                            type="text"
                            class="open"
                            @click="dialogFilterTagShow = true"
                            >{{
                                item.open ? '&lt;&lt; 收起' : '全部 &gt;&gt;'
                            }}</el-button
                        >
                    </div>
                </div>
            </template>

            <div
                v-if="filterChecked?.length"
                class="filter-item filter-checked"
            >
                <div class="item-label">已选</div>
                <div class="item-value">
                    <el-tag
                        v-for="item in filterChecked"
                        :key="item.name + item.value"
                        size="small"
                        class="filter-tag"
                        closable
                        @close="handleToClose(item)"
                        >{{ item.label }}</el-tag
                    >
                </div>
                <div class="item-btn">
                    <el-button
                        type="text"
                        icon="el-icon-delete"
                        @click="handleToClear"
                        >全部清除</el-button
                    >
                </div>
            </div>
        </div>

        <DialogFilterTag
            ref="DialogFilterTag"
            v-model="dialogFilterTagShow"
            :tags-data="tagsData"
            :current-value="industryTag"
            @change="handleDialogTagChange"
        />
    </div>
</template>

<script>
import DialogFilterTag from './DialogFilterTag.vue';

import { getIndustry, getIndustryNew, getStatic } from '/src/utils/staticData';

export default {
    name: 'HomeIndexFilter',
    components: { DialogFilterTag },
    data() {
        return {
            citysLoading: false,
            industryLoading: false,
            tagLoading: false,

            staticData: getStatic(),
            citysData: [],
            industryData: [],
            tagsData: {},

            filterOpen: false,
            filterChecked: [],
            filterData: [
                // {
                //     name: 'industryTag',
                //     label: '行业标签',
                //     open: false,
                //     type: 'checkbox',
                //     data: [],
                // },
                {
                    name: 'industryCode',
                    label: '服务行业',
                    childrenKey: 'secondIndustryCode',
                    hidden: false,
                    data: [],
                },
                {
                    name: 'secondIndustryCode',
                    label: '服务行业',
                    hidden: true,
                    parentKey: 'industryCode',
                    data: [],
                },
                {
                    name: 'updateTime',
                    label: '更新时间',
                    open: false,
                    type: 'radio',
                    // 0当年 1去年 ... 5更早
                    data: [],
                },
                {
                    name: 'filePageNum',
                    label: '文件页数',
                    open: false,
                    type: 'radio',
                    // 文档页数范围,1为1-10；2为11-20；3为21-30；4为31-50；5为50以上
                    data: [],
                },
                {
                    name: 'fileSize',
                    label: '文件大小',
                    open: false,
                    type: 'radio',
                    // 大小 1:0~5M 2:6~10M 3:11~20M 4:21~30M 5:31~50M 6:50M以上
                    data: [],
                },
            ],

            // 展示多少个行业标签
            maxItem: 15,

            dialogFilterTagShow: false,
        };
    },
    computed: {
        userInfo() {
            return this.$store.getters.userInfo || {};
        },
        vipFlag() {
            return this.$store.getters.vipFlag || false;
        },
        industryTag() {
            const { filterChecked } = this;

            const tmpData =
                filterChecked.find(fitem => fitem.name === 'industryTag') || {};

            return tmpData.value || '';
        },
    },
    created() {
        this.handleStaticDataInit();
    },
    mounted() {},
    methods: {
        // 静态数据初始化
        handleStaticDataInit() {
            const { staticData, filterData } = this;

            filterData.forEach(item => {
                if (staticData[item.name]) {
                    // 默认收起
                    item.open = false;

                    item.data = staticData[item.name].map((citem, cidx) => {
                        return this.handleItemFormat(citem, cidx);
                    });
                }
            });

            this.handleGetIndustryTags();
            this.handleGetIndustryNew();
        },

        // 每项筛选项数据格式化
        handleItemFormat(citem, cidx) {
            const { maxItem } = this;

            // 是否已选
            citem.checked = false;

            // 是否隐藏 - 选择项默认多余N个时隐藏
            citem.hidden = cidx >= maxItem;

            return citem;
        },

        // 筛选数据选中状态处理
        handleFilterInit() {
            const { filterChecked, filterData } = this;
            // 已筛选的数据
            const checkedKey = filterChecked.map(item => item.name);
            // 传递筛选值
            const tmpForm = {};

            // 取消标签操作区域对应标签选中状态
            filterData.forEach(item => {
                // 已筛选的数据
                const tmpCheckedVal = filterChecked
                    .filter(fitem => fitem.name === item.name)
                    .map(citem => citem.value);

                // 设置选中状态
                item.data.forEach(ditem => {
                    ditem.checked = tmpCheckedVal.includes(ditem.value);
                });

                // 筛选了已选条件之后，该筛选项消失，不再展示
                // item.hidden = checkedKey.indexOf(item.name) >= 0;

                // 受父级控制
                if (item.parentKey) {
                    // 父级已选
                    item.hidden = checkedKey.indexOf(item.parentKey) < 0;
                }

                // 设置字段
                tmpForm[item.name] = '';
            });

            filterChecked.forEach(item => {
                tmpForm[item.name] = item.value;
            });

            // console.log(tmpForm);

            this.$emit('change', tmpForm);
        },

        // 选中选项
        handleToCheck(item, citem) {
            if (!citem.checked) {
                const { filterChecked, industryData } = this;

                // 移除同一筛选条件，只处理单选
                let tmpCheckedData = filterChecked.filter(
                    fitem => fitem.name !== item.name
                );

                // 增加选项
                tmpCheckedData.push({
                    label: citem.label,
                    value: citem.value,
                    name: item.name,
                });

                // console.log(tmpCheckedData);

                if (item.name === 'industryCode') {
                    const tmpIndustryData = industryData.find(iitem => iitem.code === citem.value);
                    const tmpSecondIndustry = (tmpIndustryData?.children ?? []).map((titem) => {
                        return {
                            label: titem.name,
                            value: titem.code
                        };
                    });

                    // 清空子项的值
                    tmpCheckedData = tmpCheckedData.filter(
                        fitem => fitem.name !== 'secondIndustryCode'
                    );

                    this.handleSetFilterData('secondIndustryCode', tmpSecondIndustry);
                }

                this.filterChecked = tmpCheckedData;

                this.handleFilterInit();
            }
        },

        // 取消选项
        handleToClose(citem) {
            const { filterChecked } = this;
            let tmpData = filterChecked;

            if (citem.name === 'industryCode') {
                // 清空子项的值
                tmpData = tmpData.filter(
                    fitem => fitem.name !== 'secondIndustryCode'
                );
            }

            this.filterChecked = tmpData.filter(
                fitem =>
                    !(fitem.name === citem.name && fitem.value === citem.value)
            );

            this.handleFilterInit();
        },

        // 清空
        handleToClear() {
            this.filterChecked = [];

            this.handleFilterInit();
        },

        // 设置筛选数据
        handleSetFilterData(key, data) {
            const { filterData, handleItemFormat } = this;

            filterData.forEach(item => {
                if (item.name === key) {
                    item.data = data.map((citem, cidx) => {
                        return handleItemFormat(citem, cidx);
                    });
                }
            });
        },

        // 获取行业
        handleGetIndustryTags() {
            if (this.tagLoading) {
                return false;
            }

            this.tagLoading = true;

            getIndustry(data => {
                this.tagLoading = false;

                // console.log(JSON.stringify(data));

                if (data) {
                    // console.log(data);
                    this.tagsData = data;

                    // const tmpData = data.map(citem => {
                    //     return {
                    //         label: citem.name,
                    //         value: citem.code,
                    //     };
                    // });
                    const tmpData = [];
                    let maxTagLen = this.maxItem;

                    Object.keys(data).forEach(key => {
                        if (maxTagLen > 0) {
                            // console.log(data[key]);
                            (data[key] || []).forEach(item => {
                                if (maxTagLen > 0) {
                                    --maxTagLen;

                                    tmpData.push({
                                        label: item.industryTagName,
                                        value: item.industryTagName,
                                    });
                                }
                            });
                        }
                    });

                    this.handleSetFilterData('industryTag', tmpData);
                }
            });
        },

        // 获取行业
        handleGetIndustryNew() {
            if (this.industryLoading) {
                return false;
            }

            this.industryLoading = true;

            getIndustryNew((data) => {
                this.industryLoading = false;

                if (data) {
                    this.industryData = data;

                    const tmpData = data.map((citem) => {
                        return {
                            label: citem.name,
                            value: citem.code
                        };
                    });

                    console.log(tmpData);

                    this.handleSetFilterData('industryCode', tmpData);
                }
            });
        },

        handleDialogTagChange({ type, data }) {
            if (type === 'checked') {
                const { filterChecked } = this;
                let isChecked = false;

                filterChecked.forEach(item => {
                    if (item.name === 'industryTag') {
                        // 已选中，更换值
                        isChecked = true;

                        item.value = data;
                        item.label = data;
                    }
                });

                if (!isChecked) {
                    filterChecked.push({
                        name: 'industryTag',
                        label: data,
                        value: data,
                    });
                }

                this.handleFilterInit();
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import './HomeFilter.scss';
</style>
