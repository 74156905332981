<template>
    <div class="home-container">
        <HomeBanner ref="HomeBanner" @change="handleBannerChange" />

        <div class="list">
            <div class="wrap">
                <div class="list-filter">
                    <HomeFilter ref="HomeFilter" @change="handleFilterChange" />
                </div>

                <div class="list-table">
                    <HomeTable
                        ref="HomeTable"
                        :list-query="listQuery"
                        @change="handleTableChange"
                    />
                </div>
            </div>
        </div>

        <!-- <ExportCompany
            ref="ExportCompany"
            :query-param="listQuery"
            @confirm="handlePayConfirm"
        /> -->

        <OrderPay ref="OrderPay" @success="handlePaySuccess" />
        <Login ref="Login" @success="handleLoginSuccess" />
        <BuyVip ref="BuyVip" @confirm="handlePayConfirm" />
    </div>
</template>

<script>
// import ExportCompany from '/src/components/Dialog/exportCompany.vue';

import HomeBanner from './components/HomeBanner.vue';
import HomeFilter from './components/HomeFilter.vue';
import HomeTable from './components/HomeTable.vue';

import commonMixins from '/src/mixins/commonMixins';

// import { track } from '/src/directive/track/sendData';
import { deepClone } from '/src/utils/index';

import { ApiCompanyFollow } from '/src/api/search';

export default {
    name: 'HomeIndexPage',
    components: {
        // ExportCompany,
        HomeBanner,
        HomeFilter,
        HomeTable,
    },
    mixins: [commonMixins],
    data() {
        return {
            sending: false,

            listLoading: false,
            listQuery: {
                keyword: this.$route.query?.keyword || '',
                filePageNum: '',
                filePageNum: '',
                industryTag: '',
                updateTime: '',
            },

            // 当前客户数据
            companyData: {},
        };
    },
    watch: {
        vipFlag() {
            this.handleToSearch();
        },
    },
    // computed: {},
    mounted() {
        this.handleToSearch();
    },
    methods: {
        handleToBuy() {
            this.handleIsLoginAndVip();
        },

        // 筛选操作
        handleFilterChange(data) {
            const { listQuery } = this;

            // console.log(data);

            if (data === 'login' && !this.handleIsLoginAndVip()) {
                // 受控字段，未登录且不是vip
                return false;
            }

            // console.log({
            //     ...listQuery,
            //     ...data,
            // });

            // 筛选条件改变，自动发起请求
            this.listQuery = {
                ...listQuery,
                ...data,
            };

            this.handleToSearch();
        },

        // 列表搜索
        handleToSearch() {
            this.$nextTick(() => {
                this.$refs.HomeTable.handleGetList();
            });
        },

        // 列表操作
        handleTableChange({ type, data }) {
            switch (type) {
                case 'ready':
                    break;
                case 'link':
                    // if (
                    //     ['companyName', 'companyNameHighlight'].indexOf(
                    //         data.item.prop
                    //     ) >= 0
                    // ) {
                    //     this.companyData = data.row;

                    //     if (this.handleIsLoginAndVip(true)) {
                    //         // 打开客户详情 - 登录后才允许查看详情
                    //         this.$refs.CompanyDetail.handleOpen();
                    //     }

                    //     // 上报
                    //     track(
                    //         't:click_homepage_companydetails;id:' +
                    //             data.row.companyId
                    //     );
                    // }

                    break;
                case 'download':
                    if (this.handleIsLoginAndVip()) {
                        // 下载
                        this.$refs.HomeTable.handleToDownload(data);
                    }

                    break;
                case 'login':
                    this.handleIsLoginAndVip(true);
                    break;
                case 'loginAndVip':
                    this.handleIsLoginAndVip();
                    break;
                case 'buyOne':
                    this.handleToBuyOne(4, data);
                    break;
                default:
                    // if (!vipFlag) {
                    //     this.$refs.BuyVip.handleOpen();
                    // }
                    break;
            }
        },

        // Banner
        handleBannerChange({ type, data }) {
            switch (type) {
                case 'loginAndVip':
                    this.handleIsLoginAndVip();

                    break;
                default:
                    break;
            }
        },
    },
};
</script>

<style lang="scss" scoped></style>
