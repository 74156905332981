<template>
    <div class="table-container">
        <div
            v-loading="loading"
            element-loading-text=" "
            element-loading-spinner="cus-icon-loading"
            element-loading-custom-class="cus-loading"
            class="table-body"
        >
            <div
                v-for="item in listData"
                :key="item.id"
                class="list-item"
                :class="{
                    preview: item.previewStatus,
                    download: item.downloadStatus,
                }"
            >
                <div class="list-item-body">
                    <div class="list-item-header">
                        <div class="list-item-content">
                            <div
                                v-track:click
                                class="list-item-title"
                                :track-params="
                                    't:click_homepage_reporttitle;id:' + item.id
                                "
                                @click="handleToDetail(item)"
                                v-html="item.name"
                            ></div>
                            <div class="list-item-desc">
                                <span class="source">{{ item.source }}</span>
                                <span class="date">{{
                                    item.updateTime | filterDate
                                }}</span>
                                <span class="pages"
                                    >共 {{ item.filePageNum }} 页</span
                                >
                                <span class="size">{{ item.fileSize }} M</span>

                                <!-- <span
                                    v-for="titem in item.tagList"
                                    :key="titem.key"
                                    class="cus-tag"
                                    :class="titem.class"
                                    >{{ titem.label }}</span
                                > -->
                                <span v-if="item.industry" class="cus-tag">{{
                                    item.industry
                                }}</span>
                            </div>
                        </div>

                        <div class="list-item-btns">
                            <div class="btn-item btn-item-preview">
                                <el-button
                                    v-track:click
                                    type="primary"
                                    plain
                                    class="preview"
                                    :track-params="
                                        't:click_homepage_preview;id:' + item.id
                                    "
                                    @click="handleToDetail(item)"
                                    >预 览</el-button
                                >
                                <div class="btn-item-status">今日已预览</div>
                            </div>

                            <div class="btn-item btn-item-download">
                                <el-button
                                    v-loading="item.downloading"
                                    v-track:click
                                    type="colourful"
                                    class="download"
                                    :track-params="
                                        't:click_homepage_download;id:' +
                                        item.id
                                    "
                                    @click="handleDownloadClick(item)"
                                    >下 载</el-button
                                >
                                <div class="btn-item-status">今日已下载</div>
                            </div>
                        </div>
                    </div>

                    <div
                        v-if="!!keyWordValue && !!item.content"
                        class="list-item-html"
                    >
                        <span v-html="item.content"></span>
                        <el-button
                            v-track:click
                            type="text"
                            track-params="t:click_homepage_preview"
                            @click="handleToDetail(item)"
                            >&nbsp;&nbsp;查看全部内容 &gt;&gt;</el-button
                        >
                    </div>
                </div>
            </div>

            <div v-if="loaded && !listData.length" class="table-empty">
                <el-image
                    src="/images/empty.png"
                    class="table-empty-img"
                ></el-image>
                <p>呀！没找到相关内容呢~<br />换个关键词试试</p>
            </div>
        </div>

        <div v-if="listData.length" class="table-footer">
            <Pagination
                :key="pageKey"
                :small="false"
                :page="pageNo"
                :limit="pageSize"
                :total="total"
                prev-text="上一页"
                next-text="下一页"
                class="home-pagination"
                :class="{ 'last-hide': paginationLastHide }"
                @pagination="handlePaginationChange"
            />
        </div>

        <DetailReport
            ref="DetailReport"
            :detail-report-show="detailReportShow"
            :detail-report-type="detailReportType"
        />
    </div>
</template>

<script>
import Pagination from '/src/components/Pagination/index.vue';
import DetailReport from './DetailReport.vue';

import { ApiQueryCompanyList } from '/src/api/search';
import { reportDataFormat, scrollToTop } from '/src/utils/index';

import reportMixins from '/src/mixins/reportMixins';

import listCfg from './HomeTableCfg';

export default {
    name: 'HomeIndexTable',
    components: {
        Pagination,
        DetailReport,
    },
    mixins: [reportMixins],
    props: {
        listQuery: {
            type: Object,
            default() {
                return {
                    // "cityCode": "1301",
                    // "collectionStatus": "0：有 1：没有",
                    // "emailStatus": "0：有 1：没有",
                    // "estiblishTime": "（1：一年内 2：1-3年 3：3-5年 4：5-10年 5：10-15年 6：15年以上）",
                    // "followStatus": "0：有 1：没有",
                    // "industry": "123",
                    // "offset": 0,
                    // "pageNum": 0,
                    // "pageSize": 0,
                    // "phoneStatus": "0：有 1：没有",
                    // "provinceCode": "13",
                    // "regCapital": "1：50万以内 2：50-100万 3:100-500万 4：500-1000万:5：1000-5000万 6：5000万以上"
                };
            },
        },
    },
    data() {
        return {
            loading: false,
            loaded: false,

            detailReportShow: false,
            detailReportType: 'preview',

            // 搜索关键词
            keyWordValue: this.$route.query?.keyword || '',

            listData: [],
            listCfg,

            pageKey: 'pageKey',
            pageNo: 1,
            pageSize: 15,
            total: 0,
        };
    },
    computed: {
        userInfo() {
            return this.$store.getters.userInfo || {};
        },
        vipFlag() {
            return this.$store.getters.vipFlag || false;
        },
        staticData() {
            return this.$store.getters.staticData;
        },
        // 最后一个页面隐藏
        paginationLastHide() {
            const { pageNo, pageSize, total } = this;
            const tmpLen = Math.ceil(total / pageSize);

            return tmpLen - pageNo > 6;
        },
    },
    // created() {},
    mounted() {},
    methods: {
        // 获取列表
        handleGetList() {
            const tmpQuery = this.handleQueryFormat();

            if (!this.handlePaginationCheck(tmpQuery.pageNum)) {
                return false;
            }

            if (this.loading) {
                return false;
            }

            this.loading = true;

            // 返回据页面顶部300px位置，速度为100
            scrollToTop(300, 100);

            ApiQueryCompanyList(tmpQuery)
                .then(res => {
                    this.loading = false;
                    this.loaded = true;

                    const { currentPage, pageSize, recordCount, reports } =
                        res.data;

                    // console.log(reports);
                    // console.log(JSON.stringify(reports));

                    this.pageNo = parseInt(currentPage) || 1;
                    this.pageSize = parseInt(pageSize) || 15;
                    this.total = parseInt(recordCount) || 0;

                    this.listData = this.handleDataFormat(reports || []);

                    this.handleToEmit('ready');
                })
                .catch(res => {
                    this.loading = false;
                    this.loaded = true;

                    this.$confirm(
                        '<div style="text-align: center; color: #999; margin-bottom: 10px;"><img src="/images/search-empty.png" alt="empty" style="width: 250px;" /><p>关键词可能过于宽泛，建议使用更精确的搜索词哦  >~<  </p></div>',
                        '',
                        {
                            dangerouslyUseHTMLString: true,
                            confirmButtonText: '我知道了',
                            showCancelButton: false,
                            closeOnClickModal: false,
                            center: true,
                            customClass: 'keyword-message-box',
                        }
                    )
                        .then(() => {
                            this.$router.push('/');
                        })
                        .catch(() => {
                            this.$router.push('/');
                        });

                    this.handleToEmit('ready');
                });
        },

        // 筛选数据
        handleQueryFormat() {
            const { listQuery, pageNo, pageSize } = this;

            return {
                pageNum: pageNo,
                pageSize,
                ...listQuery,
            };
        },

        // 数据格式化
        handleDataFormat(data) {
            data.forEach((item, idx) => {
                // 格式化
                item = reportDataFormat(item);

                // 增加downloading状态
                item.downloading = false;
            });

            // console.log(data);

            return data;
        },

        // 打开详情
        handleToDetail(row) {
            const { userInfo } = this;

            if (!userInfo.weiXinUnionId) {
                // 未登录
                this.handleToEmit('login');

                return false;
            }

            // 新页面
            window.open('/detail/' + row.id);
        },

        // 点击下载按钮
        handleDownloadClick(row) {
            this.handleToEmit('download', row);
        },

        // 下载
        handleToDownload(row) {
            row.downloading = true;

            this.handleReportDownload({
                reportId: row.id,
                isDownload: row.downloadStatus,
                name: row.name,
            })
                .then(res => {
                    row.downloading = false;

                    // 重复下载 & 显示剩余次数
                    this.handleGetStillCount(
                        row.downloadStatus ? 'again' : 'download'
                    );

                    // 设置改行下载状态
                    row.downloadStatus = true;
                })
                .catch(reportId => {
                    row.downloading = false;

                    // 次数用完 - reportId 不为空时，弹出购买弹窗
                    if (reportId) {
                        this.handleToEmit('buyOne', reportId);
                    }
                });
        },

        // 翻页校验
        handlePaginationCheck(page) {
            const { userInfo, vipFlag } = this;
            const tmpPage = parseInt(page);

            // 用户进到第2页时需校验登录态，未登录用户需要先登录，对登录用户校验是否会员，如果不是，拉起开通VIP的支付窗口
            if (tmpPage > 1 && (!userInfo.weiXinUnionId || !vipFlag)) {
                // 页码跳回第1页
                this.pageNo = 1;
                this.pageKey = 'pageKey' + new Date().getTime();

                this.handleToEmit('loginAndVip');

                return false;
            }

            return true;
        },

        // 页码变化
        handlePaginationChange(data) {
            const { page, limit } = data;

            if (!this.handlePaginationCheck(page)) {
                return false;
            }

            this.pageNo = page;
            this.pageSize = limit;

            this.handleGetList();
        },

        // 触发父组件
        handleToEmit(type, data) {
            this.$emit('change', {
                type,
                data,
            });
        },
    },
};
</script>

<style lang="scss" scoped>
@import './HomeTable.scss';
</style>

<style lang="scss">
// 首页表格样式
.home-pagination {
    color: #535338;

    .el-pager {
        li {
            padding: 0;
            min-width: 36px;
            height: 36px;
            background: #fff;
            border: 1px solid #e5e5e5;
            border-radius: 4px;
            font-size: 12px;
            line-height: 36px;
        }
    }

    .el-pager li.active + li {
        border-left: 1px solid #e5e5e5;
    }

    span:not([class*='suffix']),
    button {
        font-size: 12px;
        min-width: 36px;
        height: 36px;
        line-height: 36px;
    }

    .btn-prev,
    .btn-next {
        width: 62px;
        background: #fff;
        border: 1px solid #e5e5e5;
    }

    &.is-background .btn-prev,
    &.is-background .btn-next,
    &.is-background .el-pager li {
        margin: 0 3px;
        background-color: #fff;
        color: #535338;
        min-width: 36px;
        border-radius: 4px;
    }

    .el-pager li.btn-quicknext,
    .el-pager li.btn-quickprev {
        line-height: 36px;
        border-color: #fff;
    }

    .el-pagination__total {
        margin-left: 10px;
        margin-right: 0;
        color: #a6a6a6;
    }

    &.last-hide {
        .el-pager {
            li {
                // 隐藏末页，业务需要
                &:last-child {
                    display: none;
                }
            }
        }
    }
}
</style>
